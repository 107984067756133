*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif Calibri !important;


  table {
    tbody {
      tr {
        transition: 0.25s linear;
        &:hover{
          background-color: rgba(255, 255, 255, 0.045);
        }
      }

    }
  }
}